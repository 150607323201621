body {
  overflow-x: hidden;
}

.fade-enter {
  transform: translateX(-100%);
}
.fade-enter-active {
  transform: translateX(0%);
}
.fade-exit {
  transform: translateX(0%);
}
.fade-exit-active {
  transform: translateX(100%);
}
.fade-enter-active {
  transition: transform 225ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
}
.fade-exit-active {
  transition: transform 195ms;
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}
